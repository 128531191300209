import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopicComponent } from './topic.component';
import { PipesModule } from 'src/@omnial/_pipes/pipes.module';
import { WidgetModule } from 'src/@omnial/shared/widget/widget.module';
import { TopicRoutingModule } from './topic-routing.module';

@NgModule({
  imports: [
    TopicRoutingModule,
    CommonModule,
    PipesModule,
    WidgetModule
  ],
  declarations: [TopicComponent],
  exports: [TopicComponent]
})
export class TopicModule { }
